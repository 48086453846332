import ROUTES from 'config/routes';
import Trans from 'next-translate/Trans';
import Image from 'next/image';
import NextLink from 'shared/components/NextLink';
import useTranslation from 'shared/hooks/useTranslation';

import INTEGRATIONS_CONFIG from '../IntegrationsConfig';
import { DescriptionPlatformCard, MainPlatformCard, StyledButton } from './Styled';

function PrestaShop(): JSX.Element {
  const { t } = useTranslation('integrationsPage');

  return (
    <>
      <MainPlatformCard color="#FFFFFF" scrollBarColor="#00AF9A">
        <div className="Content">
          <header>{INTEGRATIONS_CONFIG.PrestaShop.name}</header>
          <p>„{t('prestaShop.review')}”</p>
          <p>
            <b>Dorota Lasek</b>
            <br />
            <b>E-Commerce Manager</b>
          </p>
        </div>
        <NextLink href={ROUTES.REGISTER} passHref>
          <StyledButton color="#00AF9A">{t('integrate')}</StyledButton>
        </NextLink>
        <div className="ImageWrapper">
          <Image alt="" src="/img/logos/vet-expert.png" layout="fill" objectFit="contain" lazyBoundary="1000px" />
        </div>
      </MainPlatformCard>
      <DescriptionPlatformCard color="#00AF9A">
        <div className="Title">
          <Image
            alt=""
            src={INTEGRATIONS_CONFIG.PrestaShop.img.src}
            width={INTEGRATIONS_CONFIG.PrestaShop.img.width}
            height={INTEGRATIONS_CONFIG.PrestaShop.img.height}
            lazyBoundary="1000px"
          />
          <header>{t('mainAdvantages')}:</header>
        </div>
        <p>{t('prestaShop.description')}</p>
        <ul>
          <li>
            <b>Plug & play</b>.&nbsp;
            {t('downloadPlugin')}
          </li>
          <li>
            <Trans
              i18nKey="integrationsPage:getReviews"
              components={[<b key="bold" />]}
              fallback="integrationsPageEN:getReviews"
            />
          </li>
          <li>
            <Trans
              i18nKey="integrationsPage:widgetsOnSite"
              components={[<b key="bold" />]}
              fallback="integrationsPageEN:widgetsOnSite"
            />
          </li>
          <li>
            <Trans
              i18nKey="integrationsPage:trustOfCustomer"
              components={[<b key="bold" />]}
              fallback="integrationsPageEN:trustOfCustomer"
            />
          </li>
          <li>
            <Trans
              i18nKey="integrationsPage:salesConversion"
              components={[<b key="bold" />]}
              fallback="integrationsPageEN:salesConversion"
            />
          </li>
          <li>
            <Trans i18nKey="integrationsPage:SEO" components={[<b key="bold" />]} fallback="integrationsPageEN:SEO" />
          </li>
          <li>
            <Trans
              i18nKey="integrationsPage:importReviews"
              components={[<b key="bold" />]}
              fallback="integrationsPageEN:importReviews"
            />
          </li>
        </ul>
      </DescriptionPlatformCard>
    </>
  );
}

export default PrestaShop;
