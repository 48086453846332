import { useEffect, useState, createContext, useContext, useMemo, ReactNode } from 'react';

interface TabsContextValue {
  activeTab: string | null;
  setActiveTab: (tabName: string) => void;
}

const TabsContext = createContext<TabsContextValue | null>(null);

export function useTabs(): TabsContextValue {
  const context = useContext(TabsContext);
  if (!context) {
    throw new Error('This component must be used within a <Tabs> component.');
  }
  return context;
}

export type TabsProviderProps = {
  children: ReactNode;
  defaultActiveTab: string;
};

function TabsProvider({ children, defaultActiveTab }: TabsProviderProps): JSX.Element {
  const [activeTab, setActiveTab] = useState<string | null>(null);

  useEffect(() => {
    setActiveTab(defaultActiveTab);
  }, [defaultActiveTab]);

  const context = useMemo<TabsContextValue>(
    () => ({
      activeTab,
      setActiveTab,
    }),
    [activeTab, setActiveTab],
  );

  return <TabsContext.Provider value={context}>{children}</TabsContext.Provider>;
}

export default TabsProvider;
