import styled from 'styled-components';
import { useTabs } from '../TabsProvider';
import Shopify from './Shopify';
import PrestaShop from './PrestaShop';
import WooCommerce from './WooCommerce';
import Shoper from './Shoper';
import Home from './Home';
import IAI from './IAI';
import Magento from './Magento';
import Sote from './Sote';
import AtomStore from './AtomStore';

const GridPosition = styled.div`
  max-width: 100vw;
  padding: 0 2rem;
  display: flex;
  justify-content: flex-start;
  overflow-y: auto;
`;

const Grid = styled.article`
  display: grid;
  gap: 1.6rem;
  grid-template-columns: minmax(26rem, 2fr) 5fr;
  height: 80vh;
  margin: 0 auto;
  margin-top: 3rem;
  max-width: 130rem;
  min-height: 50rem;
  padding-bottom: 4rem;
  min-width: 65rem;

  ${({ theme }) => theme.MEDIA.MD} {
    gap: 3.8rem;
    min-width: 95rem;
    max-width: 150rem;
    max-height: 80rem;
  }

  & > section {
    box-shadow:
      0px 1.79775px 3.59551px rgba(7, 4, 146, 0.1),
      0px 21.573px 53.9326px rgba(6, 47, 125, 0.05),
      0px 10.7865px 21.573px rgba(27, 59, 119, 0.05);
    border-radius: 2rem;
    padding: 2rem;

    ${({ theme }) => theme.MEDIA.MD} {
      padding: 4rem;
    }
  }
`;

function SingleIntegration(): JSX.Element {
  const { activeTab } = useTabs();

  return (
    <GridPosition>
      <Grid>
        {activeTab === 'Shopify' ? <Shopify /> : null}
        {activeTab === 'PrestaShop' ? <PrestaShop /> : null}
        {activeTab === 'WooCommerce' ? <WooCommerce /> : null}
        {activeTab === 'Shoper' ? <Shoper /> : null}
        {activeTab === 'Home' ? <Home /> : null}
        {activeTab === 'IAI' ? <IAI /> : null}
        {activeTab === 'Magento' ? <Magento /> : null}
        {activeTab === 'Sote' ? <Sote /> : null}
        {activeTab === 'AtomStore' ? <AtomStore /> : null}
      </Grid>
    </GridPosition>
  );
}

export default SingleIntegration;
