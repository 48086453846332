import { ReactNode } from 'react';
import styled from 'styled-components';
import Image from 'next/image';

import Tabs from './Tabs';

import { Section } from '../../Styled';
import INTEGRATIONS_CONFIG from './IntegrationsConfig';
import SingleIntegration from './SingleIntegration';

const TabsWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 10rem;
  justify-content: flex-start;
  margin: 0 auto;
  max-width: 100%;
  overflow-x: auto;
  padding-bottom: 1rem;
  width: fit-content;
`;

const StyledImage = styled(Image)`
  img {
    max-width: 5rem;
  }
`;

const Wrapper = styled.div`
  display: flex;
  font-family: ${({ theme }) => theme.FONTS.MAIN};
  flex-direction: column;
  overflow: hidden;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;

  ${({ theme }) => theme.MEDIA.XL} {
    justify-content: center;
  }
`;

interface BenefitsDesktopProps {
  children?: ReactNode;
}

function BenefitsDesktop({ children }: BenefitsDesktopProps): JSX.Element {
  return (
    <Section>
      {children || null}
      <Tabs defaultActiveTab={INTEGRATIONS_CONFIG.Shopify.name}>
        <TabsWrapper>
          {Object.keys(INTEGRATIONS_CONFIG).map((keyName) => (
            <Tabs.Tab
              key={INTEGRATIONS_CONFIG[keyName].name}
              label={INTEGRATIONS_CONFIG[keyName].name}
              isVertical={INTEGRATIONS_CONFIG[keyName].img.width > 70}
              themeColor={INTEGRATIONS_CONFIG[keyName].themeColor}
            >
              <StyledImage
                src={INTEGRATIONS_CONFIG[keyName].img.src}
                alt={INTEGRATIONS_CONFIG[keyName].name}
                layout="fill"
                objectFit="contain"
                objectPosition="center center"
              />
            </Tabs.Tab>
          ))}
        </TabsWrapper>
        <Wrapper>
          {Object.keys(INTEGRATIONS_CONFIG).map((keyName) => (
            <Flex key={INTEGRATIONS_CONFIG[keyName].name}>
              <Tabs.Panel label={INTEGRATIONS_CONFIG[keyName].name}>
                <SingleIntegration />
              </Tabs.Panel>
            </Flex>
          ))}
        </Wrapper>
      </Tabs>
    </Section>
  );
}

export default BenefitsDesktop;
