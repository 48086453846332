import styled from 'styled-components';
import Button from 'shared/components/Button';
import { scrollBar } from 'shared/components/mixins';

interface MainColorProps {
  color: string;
}

interface MainPlatformCardProps extends MainColorProps {
  scrollBarColor: string;
}

export const MainPlatformCard = styled.section<MainPlatformCardProps>`
  background-color: ${({ color }) => color};
  color: #000000;
  overflow: hidden;
  position: relative;

  header {
    font-size: 2rem;
    font-weight: 600;
    line-height: 3rem;
    margin-bottom: 0.6rem;

    ${({ theme }) => theme.MEDIA.LG} {
      font-size: 3rem;
      font-weight: 600;
      line-height: 4.6rem;
      margin-bottom: 1.2rem;
    }
  }

  p {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2rem;
    margin-bottom: 0.6rem;

    ${({ theme }) => theme.MEDIA.LG} {
      font-size: 2rem;
      font-weight: 400;
      line-height: 2.3rem;
      margin-bottom: 1.2rem;
    }
  }

  .Content {
    direction: rtl;
    height: calc(100% - 11rem);
    margin-left: -0.8rem;
    overflow-y: auto;
    padding-left: 0.8rem;
    position: inherit;
    z-index: 2;
    ${scrollBar};

    &::-webkit-scrollbar-thumb {
      background-color: ${({ scrollBarColor }) => scrollBarColor};
    }

    & > * {
      direction: ltr;
    }
  }

  .ImageWrapper {
    align-items: center;
    bottom: 8rem;
    display: flex;
    height: 7rem;
    justify-content: center;
    left: 0;
    margin: 0 auto;
    max-width: 80%;
    position: absolute;
    right: 0;

    ${({ theme }) => theme.MEDIA.MD} {
      max-width: 90%;
    }
  }
`;

export const DescriptionPlatformCard = styled.section<MainColorProps>`
  background-color: white;
  color: #0b0914;
  max-height: 100%;
  overflow-y: auto;
  ${scrollBar};

  &::-webkit-scrollbar-track {
    margin-bottom: 1.6rem;
    margin-top: 1.6rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ color }) => color};
  }

  header {
    color: #0b0914;
    font-size: 2rem;
    font-weight: 600;
    line-height: 3rem;

    ${({ theme }) => theme.MEDIA.LG} {
      font-size: 3.2rem;
      font-weight: 600;
      line-height: 4.7rem;
    }
  }

  p {
    color: #919191;
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: 0.04em;
    line-height: 2.4rem;
    margin-bottom: 2rem;

    @media (max-width: ${({ theme }) => theme.BREAKPOINT.MD}) {
      text-align: center;
    }

    ${({ theme }) => theme.MEDIA.LG} {
      font-size: 2.2rem;
      font-weight: 500;
      letter-spacing: 0.04em;
      line-height: 2.9rem;
      margin-bottom: 3rem;
    }
  }

  ul {
    list-style: none;
    margin-left: 0;
    position: relative;

    li {
      color: #35353f;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 2rem;
      padding-bottom: 1.2rem;
      padding-left: 2rem;

      ${({ theme }) => theme.MEDIA.LG} {
        font-size: 2rem;
        font-weight: 500;
        letter-spacing: 0.04em;
        line-height: 2.4rem;
        padding-bottom: 1.8rem;
      }

      :before {
        background-color: ${({ color }) => color};
        content: '';
        height: 2rem;
        mask: url('/img/icons/check.svg') no-repeat 50% 50%;
        mask-size: cover;
        position: absolute;
        transform: translate(-2.4rem, 0.1rem);
        width: 2rem;
      }
    }
  }

  .Title {
    align-items: center;
    display: flex;
    margin-bottom: 1.6rem;
    position: relative;

    @media (max-width: ${({ theme }) => theme.BREAKPOINT.MD}) {
      flex-direction: column;
      justify-content: center;
    }

    ${({ theme }) => theme.MEDIA.MD} {
      span {
        transform: translateX(-1.6rem);
      }
    }
  }
`;

export const StyledButton = styled(Button)<MainColorProps>`
  align-items: center;
  background-color: ${({ color }) => color};
  bottom: 2rem;
  box-shadow:
    0px 3.03895px 6.07791px rgba(7, 4, 146, 0.1),
    0px 36.4674px 91.1686px rgba(6, 47, 125, 0.05),
    0px 18.2337px 36.4674px rgba(27, 59, 119, 0.05);
  display: flex;
  justify-content: center;
  position: absolute;
  text-align: center;
  width: calc(100% - 4rem);
  z-index: 2;

  ${({ theme }) => theme.MEDIA.MD} {
    width: calc(100% - 8rem);
  }

  &:hover {
    background-color: ${({ color }) => color};
    filter: brightness(85%);
  }
`;
