import styled from 'styled-components';
import { buttonStyles } from 'shared/components/mixins';

export const ButtonATag = styled.a`
  ${buttonStyles};
`;

const Button = styled.button`
  ${buttonStyles};
`;

export default Button;
