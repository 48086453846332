interface ImgProps {
  src: string;
  width: number;
  height: number;
}

interface PlatformProps {
  name: string;
  img: ImgProps;
  themeColor: string;
  href?: string;
}

interface IntegrationConfigType {
  [key: string]: PlatformProps;
}

const INTEGRATIONS_CONFIG: IntegrationConfigType = {
  Shopify: {
    name: 'Shopify',
    img: {
      src: '/img/logos/shopify.png',
      width: 57,
      height: 59,
    },
    themeColor: '#A7D157',
    href: 'https://platforms.trustmate.io/pl/shopify-trustmate',
  },
  PrestaShop: {
    name: 'PrestaShop',
    img: {
      src: '/img/logos/presta.png',
      width: 60,
      height: 62,
    },
    themeColor: '#A5DBE8',
    href: 'https://platforms.trustmate.io/pl/prestashop-trustmate',
  },
  WooCommerce: {
    name: 'WooCommerce',
    img: {
      src: '/img/logos/woo.png',
      width: 75,
      height: 46,
    },
    themeColor: '#9B5C8F',
    href: 'https://platforms.trustmate.io/pl/woocommerce-trustmate',
  },
  Shoper: {
    name: 'Shoper',
    img: {
      src: '/img/logos/shoper.png',
      width: 108,
      height: 35,
    },
    themeColor: '#126FB7',
    href: 'https://platforms.trustmate.io/pl/shopertrustmate',
  },
  Home: {
    name: 'Home',
    img: {
      src: '/img/logos/home.png',
      width: 119,
      height: 35,
    },
    themeColor: '#E20000',
    href: 'https://platforms.trustmate.io/pl/homepltrustmate',
  },
  IAI: {
    name: 'IAI',
    img: {
      src: '/img/logos/iai.png',
      width: 66,
      height: 34,
    },
    themeColor: '#FFB82A',
    href: 'https://platforms.trustmate.io/pl/iai-trustmate',
  },
  Magento: {
    name: 'Magento',
    img: {
      src: '/img/logos/magento.png',
      width: 60,
      height: 62,
    },
    themeColor: '#EC6737',
    href: 'https://platforms.trustmate.io/pl/magento-trustmate',
  },
  Sote: {
    name: 'Sote',
    img: {
      src: '/img/logos/sote.png',
      width: 68,
      height: 55,
    },
    themeColor: '#ED1B24',
    href: 'https://platforms.trustmate.io/pl/pl/sote-trustmate-0',
  },
  AtomStore: {
    name: 'AtomStore',
    img: {
      src: '/img/logos/atomstore.png',
      width: 52,
      height: 50,
    },
    themeColor: '#FF0102',
    href: 'https://platforms.trustmate.io/pl/pl/atomstore-trustmate-0-0',
  },
};

export default INTEGRATIONS_CONFIG;
