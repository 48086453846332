import styled from 'styled-components';

import ROUTES from 'config/routes';
import Trans from 'next-translate/Trans';
import Image from 'next/image';
import NextLink from 'shared/components/NextLink';
import useTranslation from 'shared/hooks/useTranslation';

import INTEGRATIONS_CONFIG from '../IntegrationsConfig';
import { DescriptionPlatformCard, MainPlatformCard, StyledButton } from './Styled';

const MainPlatformCardForShoper = styled(MainPlatformCard)`
  color: white;
`;

const StyledButtonForShoper = styled(StyledButton)`
  color: #126fb7;
`;

function Shoper(): JSX.Element {
  const { t } = useTranslation('integrationsPage');

  return (
    <>
      <MainPlatformCardForShoper color="#126FB7" scrollBarColor="#FFFFFF">
        <div className="Content">
          <header>{INTEGRATIONS_CONFIG.Shoper.name}</header>
          <p>„{t('shoper.review')}”</p>
          <p>
            <b>Jarek Kulewicz</b>
            <br />
            <b>CEO ANSIN</b>
          </p>
        </div>
        <NextLink href={ROUTES.REGISTER} passHref>
          <StyledButtonForShoper color="#FFFFFF">{t('integrate')}</StyledButtonForShoper>
        </NextLink>
        <div className="ImageWrapper">
          <Image alt="" src="/img/logos/ansin.png" layout="fill" objectFit="contain" lazyBoundary="1000px" />
        </div>
      </MainPlatformCardForShoper>
      <DescriptionPlatformCard color="#126FB7">
        <div className="Title">
          <Image
            alt=""
            src={INTEGRATIONS_CONFIG.Shoper.img.src}
            width={INTEGRATIONS_CONFIG.Shoper.img.width}
            height={INTEGRATIONS_CONFIG.Shoper.img.height}
            lazyBoundary="1000px"
          />
          <header>{t('mainAdvantages')}:</header>
        </div>
        <p>{t('shoper.description')}</p>
        <ul>
          <li>
            <b>Plug & play</b>.&nbsp;
            {t('oneClick')}
          </li>
          <li>
            <Trans
              i18nKey="integrationsPage:getReviews"
              components={[<b key="bold" />]}
              fallback="integrationsPageEN:getReviews"
            />
          </li>
          <li>
            <Trans
              i18nKey="integrationsPage:widgetsOnSite"
              components={[<b key="bold" />]}
              fallback="integrationsPageEN:widgetsOnSite"
            />
          </li>
          <li>
            <Trans
              i18nKey="integrationsPage:trustOfCustomer"
              components={[<b key="bold" />]}
              fallback="integrationsPageEN:trustOfCustomer"
            />
          </li>
          <li>
            <Trans
              i18nKey="integrationsPage:salesConversion"
              components={[<b key="bold" />]}
              fallback="integrationsPageEN:salesConversion"
            />
          </li>
          <li>
            <Trans i18nKey="integrationsPage:SEO" components={[<b key="bold" />]} fallback="integrationsPageEN:SEO" />
          </li>
          <li>
            <Trans
              i18nKey="integrationsPage:importReviews"
              components={[<b key="bold" />]}
              fallback="integrationsPageEN:importReviews"
            />
          </li>
        </ul>
      </DescriptionPlatformCard>
    </>
  );
}

export default Shoper;
