import { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import Head from 'next/head';
import TabsProvider, { TabsProviderProps, useTabs } from './TabsProvider';

type StyledButtonProps = {
  isVertical: boolean;
};

const StyledButton = styled.button<StyledButtonProps>`
  background: transparent;
  border: none;
  color: ${({ theme }) => theme.COLORS.ASH};
  cursor: pointer;
  font-size: 1.8rem;
  font-weight: 600;
  min-height: 0.3rem;
  min-width: 6rem;
  position: relative;
  transition: 0.1s;
  ${({ isVertical }) =>
    isVertical &&
    css`
      min-width: 10rem;
      width: 8rem;
    `}

  &:focus {
    outline: none;
  }
`;

type ActiveProps = {
  backgroundColor: string;
};

const Active = styled.div<ActiveProps>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  bottom: 0;
  height: 0.4rem;
  left: 0;
  position: absolute;
  width: 100%;
`;

type StyledDivProps = {
  active: boolean;
};

const StyledDiv = styled.div<StyledDivProps>`
  cursor: pointer;
  display: flex;
  height: 100%;
  padding: 2.4rem;
  position: relative;
`;

type PanelProps = {
  children: ReactNode;
  label?: string;
};

function Panel({ label, children }: PanelProps): JSX.Element | null {
  const { activeTab } = useTabs();
  return activeTab === label ? <div>{children}</div> : null;
}

Panel.defaultProps = {
  label: '',
};

type TabProps = {
  children: ReactNode;
  label: string;
  isVertical: boolean;
  themeColor: string;
};

function Tab({ label, children, isVertical, themeColor }: TabProps): JSX.Element {
  const { setActiveTab, activeTab } = useTabs();
  function selectTab(): void {
    setActiveTab(label);
  }

  return (
    <>
      <Head>
        {activeTab === label ? (
          <script>
            {`
              (() => {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                  event: 'tabs_content',
                  eventCategory: '${activeTab}',
                  eventAction: 'Kliknięcie zakładki',
                });
              })();
            `}
          </script>
        ) : null}
      </Head>
      <StyledDiv
        active={activeTab === label}
        // eslint-disable-next-line react/jsx-no-bind
        onClick={selectTab}
      >
        <StyledButton isVertical={isVertical}>{children}</StyledButton>
        {activeTab === label ? <Active backgroundColor={themeColor} /> : null}
      </StyledDiv>
    </>
  );
}

function Tabs({ children, defaultActiveTab }: TabsProviderProps): JSX.Element {
  return <TabsProvider defaultActiveTab={defaultActiveTab}>{children}</TabsProvider>;
}

Tabs.Tab = Tab;
Tabs.Panel = Panel;

export default Tabs;
